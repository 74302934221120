<template>
    <div class="subscription-wrapper" :class="{
        featured: activeItem,
    }" :for="this.data.pricePlanId">
        <div class="top-side">
            <div v-if="activeItem" class="featured-label">Most popular</div>
            <div class="title-wrapper">
                <h2 class="column-title">{{ data.displayTitle }}</h2>
            </div>

            <div class="info-wrapper">

                <div class="price-wrapper">
                    <div class="column-subtitle">{{ data.payOff }}</div>
                    {{ data.price }}
                    <small v-if="data.subtitle">{{ data.subtitle }}</small>
                </div>

                <div class="features-list" v-html="data.features"></div>
            </div>
        </div>

        <div class="bottom-side">

            <div class="button-wrapper">
                <router-link v-if="data?.buttonUrl && data.buttonUrl !== ''" type="button" :to="data.buttonUrl"
                    class="button">
                    {{ data.buttonLabel }}
                </router-link>
                <button v-else-if="!data?.buttonUrl || data?.buttonUrl && data.buttonUrl == ''" type="button"
                    class="button" @click="clickedThisItem">
                    {{ data.buttonLabel }}
                </button>
            </div>

            <!-- <div class="button-wrapper">
                <router-link v-if="data.buttonLabel && data.buttonLabel !== '' && data.buttonLabel == 'Join waitlist'"
                    type="button" to="/waitlist" class="button">
                    {{ data.buttonLabel }}
                </router-link>
                <router-link v-else-if="data.buttonLabel && data.buttonLabel !== ''" type="button" to="/contact"
                    class="button">
                    {{ data.buttonLabel }}
                </router-link>
            </div> -->
        </div>
    </div>
</template>

<script>
import { checkoutSubscription } from "@streampac.io/chef_sp_1";

//import { current_enviroment } from "@/constants/endpoints";

export default {
    //emits: ["chosenSubscriptionItem"],
    props: ["data", "userData"],
    inject: ["isAuthenticated"],
    data() {
        return {
            activeItem: false,
        };
    },
    created() {
        //console.log(this.data);
        //console.log(this.data.pricePlanId);

        if (this.data && this.data.title.includes("_featured")) {
            this.activeItem = true;
        }
    },
    computed: {
        checkClass() {
            // if (
            // 	this.data.name === this.userData.subscription ||
            // 	this.data.stripePriceId === this.userData.subscription ||
            // 	this.data.stripePriceIdTest === this.userData.subscription
            // ) {
            // 	return "account-set";
            // }
            return "";
        },
        checkActiveState() {
            // if (this.data.name === this.userData.subscription) {
            // 	return true;
            // }
            return false;
        },
    },
    methods: {
        clickedThisItem() {
            if (!this.isAuthenticated) {
                this.$store.commit("toggleLoginPopup");
            } else if (this.isAuthenticated) {

                if (this.data?.pricePlanId && this.data.pricePlanId !== '') {

                    let getPricePlanId = this.data.pricePlanId;

                    let postObj = {
                        //subscriptionPriceId: chosenId,
                        pricePlanId: getPricePlanId,
                    };

                    checkoutSubscription(this.isAuthenticated, postObj).then(
                        (response) => {
                            if (response.succes) {
                                window.location.replace(response.redirectUrl);
                            }
                        }
                    );

                }
            }

            // else {
            //     if (this.$route.name == "HappySoulTravel") {
            //         this.$router.push({
            //             name: "RegisterPage",
            //             params: { referer: "HappySoulTravel" },
            //         });
            //     } else if (this.$route.name == "DelightYogaMembers") {
            //         this.$router.push({
            //             name: "RegisterPage",
            //             params: { referer: "DelightYogaMembers" },
            //         });
            //     } else if (this.$route.name == "DelightCommunityMembers") {
            //         this.$router.push({
            //             name: "RegisterPage",
            //             params: { referer: "DelightCommunityMembers" },
            //         });
            //     } else {
            //         //router push to sign-up
            //         this.$router.push("/sign-up");
            //     }
            // }
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>